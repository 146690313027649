<template>
    <span class="translate-input-wrapper">
        <input
            :value="value"
            :placeholder="placeholder"
            type="text"
            :class="classObj"
            class="form-control"
            name=""
            @keyup.up="goUpInSuggestions"
            @keyup.down="goDownInSuggestions"
            @input="getTranslation"
            @keyup.enter="selectSuggestion"
            :dir="this.currentLocale === 'ur'? 'rtl': 'ltr'">
        <ul
            v-if="suggestions.length > 0"
            class="translations"
            :class="this.currentLocale === 'ur' ? 'translations-right' : ''">
            <li
                v-for="(eachSuggestion, index) in suggestions"
                :class="{ 'active': index === selectedSuggestion }"
                :key="index"
                @click="selectTranslatedWord(eachSuggestion)">{{ eachSuggestion }}</li>
        </ul>
    </span>
</template>

<script>
    import mixins from '@/mixins';

    export default {
        name: 'Tranliteration',
        mixins: [
            mixins
        ],
        props: {
            value: {
                type: String
            },
            oninput: {
                type: Function,
                required: true
            },
            placeholder: {
                type: String
            },
            classObj: {
                type: Object
            }
        },
        data () {
            return {
                suggestions: [],
                selectedSuggestion: 0,
                currentLocale: ''
            };
        },
        computed: {

        },
        watch: {
        },
        created () {
            this.currentLocale = this.getLanguageCode(process.env.LANGUAGE);
        },
        methods: {
            getTranslation (e) {
                this.oninput(e.target.value);
                const fullString = e.target.value;
                const lastWord = fullString.split(' ').pop();

                const that = this;
                if (lastWord === '') {
                    this.suggestions = [];
                    this.selectedSuggestion = 0;
                }

                if (this.currentLocale !== 'en' && lastWord.trim().length > 0) {
                    this.translateWord(lastWord, (suggestions) => {
                        that.suggestions = suggestions;
                    });
                }
            },
            selectTranslatedWord (selectedWord) {
                const currentText = this.value;
                const words = currentText.split(' ');
                if(selectedWord && selectedWord.toString().trim() !== "undefined") {
                    words.pop();
                    words.push(selectedWord);
                }
                const textWithoutLastWord = words.join(' ');
                this.oninput(textWithoutLastWord);
                this.suggestions = [];
                this.selectedSuggestion = 0;
            },
            goUpInSuggestions () {
                if (this.suggestions.length === 0) {
                    return;
                }

                const totalSuggestions = this.suggestions.length;
                if (this.selectedSuggestion === 0) {
                    return;
                }
                this.selectedSuggestion--;
            },
            goDownInSuggestions () {
                if (this.suggestions.length === 0) {
                    return;
                }

                const totalSuggestions = this.suggestions.length;
                if (this.selectedSuggestion >= (totalSuggestions - 1)) {
                    return;
                }
                this.selectedSuggestion++;
            },
            selectSuggestion () {
                this.selectTranslatedWord(this.suggestions[this.selectedSuggestion] + ' ');
            }
        }
    };
</script>

<style lang="scss" scoped>
.translate-input-wrapper {
    position: relative;
    display: block;
    .form-control.error {
        border-color: #d00b12;
    }
    .translations {
        padding: 0;
        margin: 0;
        position: absolute;
        background: #fff;
        z-index: 100;
        width: 100%;
        border-top: 0;
        text-align: left;
        li {
            list-style: none;
            padding: 3px 10px;
            border-left: 1px solid #e9e9e9;
            border-right: 1px solid #e9e9e9;
            &.active {
                background: #e9e9e9;
            }
        }
    }
    .translations-right {
        text-align: right;
    }
}
</style>
