<template>
    <!-- Report Modal -->
    <div
        id="multiinput"
        class="modal fade multiinput"
        tabindex="-1"
        role="dialog"
        aria-labelledby="multiinputLabel"
        aria-hidden="true">
        <div
            class="modal-dialog"
            role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ getModalTitle }}</h5>
                    <button
                        type="button"
                        class="close"
                        :class="this.currentLocale === 'ur' ? 'ml-20-neg' : ''"
                        data-dismiss="modal"
                        aria-label="Close">
                        <i class="pratilipi-icons">&#xe801;</i>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label :class="this.currentLocale === 'ur' ? 'float-right' : ''">{{ getInputModalSubheadings[0] }}</label>
                            <TranslatingInput
                                :value="getModalActionAndData.prefilled_value"
                                :oninput="updatePrefilledValue"/>
                        </div>
                        <div
                            v-if="this.currentLocale !== 'en'"
                            class="form-group">
                            <label :class="this.currentLocale === 'ur' ? 'float-right' : ''">{{ getInputModalSubheadings[1] }}</label>
                            <input
                                :value="getModalActionAndData.prefilled_value_two"
                                type="text"
                                class="form-control"
                                placeholder="__('writer_input_title_en')"
                                @input="updatePrefilledValueTwo($event.target.value)">
                        </div>
                        <div
                            :class="this.currentLocale === 'ur' ? 'flex-magic': ''">
                            <button
                                type="button"
                                class="btn btn-submit"
                                @click="dispatchMultipleInputActionAndCloseModal">__("submit")</button>
                            <button
                                type="button"
                                class="cancel"
                                data-dismiss="modal"
                                aria-label="Close">__("cancel")</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TranslatingInput from '@/components/TranslatingInput.vue';
    import { mapActions, mapGetters } from 'vuex';
    import mixins from '@/mixins';

    export default {
        mixins: [
            mixins
        ],
        data () {
            return {
                currentLocale: ''
            };
        },
        computed: {
            ...mapGetters([
                'getModalTitle',
                'getModalActionAndData',
                'getInputModalSubheadings'
            ])
        },
        methods: {
            ...mapActions([
                'dispatchMultipleInputAction',
                'updatePrefilledValue',
                'updatePrefilledValueTwo'
            ]),
            dispatchMultipleInputActionAndCloseModal () {
                $('#multiinput').modal('hide');
                this.dispatchMultipleInputAction();
            }
        },
        components: {
            TranslatingInput
        },
        created () {
            this.currentLocale = this.getLanguageCode(process.env.LANGUAGE);
        }
    };
</script>

<style lang="scss" scoped>
.multiinput {
    text-align: left;
    max-width: 350px;
    margin: 50px auto;
    .modal-body {
        padding-top: 0;
    }
    .form-group {
        font-size: 14px;
    }
    .btn-submit {
        background: #d0021b;
        color: #fff;
        border: 0;
        font-size: 14px;
        float: right;
    }
    .cancel {
        background: none;
        border: 0;
        float: right;
        font-size: 12px;
        line-height: 33px;
    }
}
.ml-20-neg {
    margin-left: -20px;
}
.flex-magic {
    display: flex;
    flex-direction: row-reverse;
}
</style>
